<template>
  <div class="craigslist-full-service">
    <el-row>
      <h1 class="header">
        Craigslist Full Service
      </h1>
    </el-row>
    <el-row>
      <el-tabs
        :value="$route.name"
        @tab-click="$route.name !== $event.name ? $router.push({ name: $event.name }) : null"
      >
        <el-tab-pane
          v-for="tab in tabs"
          :key="tab.route"
          :label="tab.label"
          :name="tab.route"
        />
        <router-view :key="$route.fullPath" />
      </el-tabs>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'CraigslistFullService',
  computed: {
    isStaff () {
      return this.$store.getters['auth/isStaff']
    },
    tabs () {
      return this.isStaff
        ? [
          { label: 'Posting Queue', route: 'PostingQueue' },
          { label: 'Missed Tasks', route: 'MissedTasks' },
          { label: 'Phone Verification', route: 'PhoneVerification' },
          { label: 'Verification Numbers', route: 'VerificationNumbers' },
          { label: 'Administration', route: 'FullServiceAdministration' },
          { label: 'Properties', route: 'FullServiceProperties' }
        ]
        : [
          { label: 'Posting Queue', route: 'PostingQueue' },
          { label: 'Missed Tasks', route: 'MissedTasks' },
          { label: 'Phone Verification', route: 'PhoneVerification' }
        ]
    }
  }
}
</script>

<style lang="scss" scoped>
.craigslist-full-service {
  .header {
    margin-top: 0;
    margin-bottom: 0.5em;
    font-size: 2rem;
  }
}
</style>
